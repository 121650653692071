<template>
  <el-container>
    <Header pageName="小程序认证"></Header>
    <el-main>
      <el-form class="el-form-search" label-width="90px">
        <el-form-item label="客户信息：">
          <el-input v-model="title" size="small" placeholder="请输入法人姓名\联系电话\微信号\公司名称"></el-input>
        </el-form-item>
        <el-form-item label="申请日期：">
          <el-date-picker v-model="create_time" size="small" type="daterange" value-format="timestamp" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="name" label="公司名称" align="center"></el-table-column>
        <el-table-column prop="code" label="营业执照" align="center"></el-table-column>
        <el-table-column prop="legal_persona_name" label="法人姓名" align="center"></el-table-column>
        <el-table-column prop="legal_persona_wechat" label="法人微信" align="center"></el-table-column>
        <el-table-column prop="component_phone" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="price" label="付款金额" align="center"></el-table-column>
        <el-table-column prop="create_time" label="申请日期" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </el-container>
</template>

<script>
import Header from './components/header';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Header,
    Paging,
  },
  data() {
    return {
      title: '',
      create_time: '',
      page: 1,
      rows: 10,
      total: 0,
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    cancelSearch() {
      this.page = 1;
      this.rows = 10;
      this.title = '';
      this.create_time = '';
      this.getList();
    },
    getList() {
      let data = {
        title: this.title,
        page: this.page,
        rows: this.rows,
      };
      if (this.create_time) {
        data.starttime = this.create_time[0] / 1000;
        data.endtime = this.create_time[1] / 1000;
      }
      this.$axios.post(this.$api.admin.appletAuthentication, data).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      margin-top: 10px;
    }
  }
}
</style>